import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted, watch } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertLeaveApply, selectHolidayHistoryByPagination, selectRemainDay, getLeaveDays } from '@/api/officeApi';
import moment from 'moment';
import Cookies from "js-cookie";
import useTablePagination from '@/hooks/pagination';
import leaveTable from './leaveTable.vue';
import { selectApprovalItem } from '@/api/material';
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined,
    leaveTable
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      leaveType: undefined,
      time: [[]],
      leaveCause: '',
      fileUrl: [],
      itemId: null,
      leaveDay: 0
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      validateTime
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      leaveCause: [{
        required: true,
        message: '请假事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      leaveType: [{
        required: true,
        message: '请假类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      time: [{
        required: true,
        message: '请假时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }] // fileUrl: [
      //   {
      //     required: true,
      //     validator: signFileurl,
      //     type: 'array',
      //     // trigger: 'change',
      //     trigger: formState.fileUrl.length == 0,
      //   },
      // ]

    }; //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        let isOk = false;
        isOk = findDataIsOk(formState.time); // console.log(isOk)

        if (isOk) {
          _message.error('请假时间段不能有重合');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        let arr = [];
        obj.time.forEach(item => {
          arr.push(item.join("~"));
        });
        obj.startTime = arr.join(","); // console.log(obj)

        loading.value = true;
        let res = await insertLeaveApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findDataIsOk = times => {
      for (let i = 0; i < times.length; i++) {
        for (let j = 0; j < times.length; j++) {
          let is = moment(times[i][0]).valueOf();
          let ie = moment(times[i][1]).valueOf();
          let js = moment(times[j][0]).valueOf();
          let je = moment(times[j][1]).valueOf();

          if (i == j) {
            if (is == ie) {
              return true;
            } else {
              continue;
            }
          } else {
            if (is <= je && is >= js || ie >= js && ie <= je || is <= js && ie >= je || is >= js && ie <= je) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    }; //查看历史请假记录


    const historicalVisible = ref(false);

    const toView = async () => {
      historicalVisible.value = true;
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push([]);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    };

    const uid = Cookies.get('uid'); //假期类型

    const remainDayData = ref([]);

    const getRemainDay = async () => {
      let res = await selectRemainDay({
        uid: Cookies.get('uid')
      });

      if (res.code === 200) {
        remainDayData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.holidayName,
              isDayNum: item.isDayNum,
              dayNum: item.dayNum
            };
            remainDayData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const getLeaveDay = (val, label) => {
      let obj = null;

      if (remainDayData.value.length) {
        obj = remainDayData.value.find(item => item.value === val);
      }

      if (obj) {
        return obj[label];
      } else {
        return false;
      }
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 9,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    watch(() => formState, () => {
      // console.log(111111111,formState.time)
      let arr = formState.time.filter(item => item.length != 0);

      if (arr.length) {
        getLeaveDay1(arr);
      } else {
        formState.leaveDay = 0;
      }
    }, {
      deep: true
    });

    const getLeaveDay1 = async arr => {
      let arr1 = [];
      arr.forEach(item => {
        arr1.push(item.join("~"));
      });
      let res = await getLeaveDays({
        startTime: arr1.join(",")
      });

      if (res.code == 200) {
        formState.leaveDay = res.data;
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getRemainDay();
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      toView,
      historicalVisible,
      disabledDate,
      handleAdd,
      onDelete,
      validateTime,
      uid,
      remainDayData,
      getLeaveDay,
      projectOptions
    };
  }

});