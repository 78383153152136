import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { ProfileOutlined, DollarOutlined, BranchesOutlined, PartitionOutlined, ImportOutlined } from '@ant-design/icons-vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import getFieldRules from '@/utils/fieldRules';
import moment from 'moment';
import { selectLeaveApplyById, updateLeaveApply, updateToLeaveApproval, updateRevokeApply, selectRemainDay, getLeaveDays } from '@/api/officeApi'; // import { useStore } from 'vuex'

import leaveTable from './leaveTable.vue';
import { selectApprovalItem } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    ProfileOutlined,
    DollarOutlined,
    uploadImgs,
    BranchesOutlined,
    PartitionOutlined,
    ImportOutlined,
    leaveTable
  },
  props: {
    // applyVisible: {
    //   type: Boolean,
    //   default: false
    // },
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    // const store = useStore()
    const formRef = ref();
    const formState = reactive({
      theme: "",
      leaveType: undefined,
      time: [[]],
      leaveCause: '',
      fileUrl: [],
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      // leaveEffect: null,
      content: "",
      //审批意见
      // annotation:[], //批注后文件
      revisePayroll: [],
      //修订后合同
      updateContractList: [],
      //修订后合同集
      reviseCon: [],
      //修改时需要修改修订后合同时用
      leaveRevoke: {
        revokeType: 1,
        revokeType: undefined,
        leaveDay: undefined
      },
      isRevoked: 0,
      // withHold:100,
      timeRe: [undefined],
      itemId: null
    });
    const {
      signFileurl,
      validateTime
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      leaveCause: [{
        required: true,
        message: '请假事由不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      leaveType: [{
        required: true,
        message: '请假类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      // revokeType: [
      //   {
      //     required: true,
      //     message: '撤销请假选择不能为空',
      //     trigger: 'change',
      //     type: 'number',
      //   },
      // ],
      time: [{
        required: true,
        message: '请假时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      timeRe: [{
        required: true,
        message: '撤销后请假时间不能为空',
        trigger: 'change',
        validator: validateTime
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }],
      // fileUrl: [
      //   {
      //     required: true,
      //     validator: signFileurl,
      //     type: 'array',
      //     // trigger: 'change',
      //     trigger: formState.fileUrl.length == 0,
      //   },
      // ],
      revisePayroll: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.revisePayroll.length == 0
      }],
      reviseCon: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.reviseCon.length == 0
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        //详情
        return true;
      } else if (props.applyDetailData.state == 1) {
        //审批
        return true;
      } else if (props.applyDetailData.state == 2) {
        //修改
        return false;
      } else if (props.applyDetailData.state == 99) {
        //人事审批
        return true;
      } else if (props.applyDetailData.state == 31) {
        //已完成
        return true;
      } else if (props.applyDetailData.state == 32) {
        //已完成
        return true;
      } else if (props.applyDetailData.state == 33) {
        //已完成
        return true;
      }
    });
    const isDisabledForRe = computed(() => {
      if (props.applyDetailData.state === 0) {
        //详情
        return true;
      } else if (props.applyDetailData.state == 1) {
        //审批
        return true;
      } else if (props.applyDetailData.state == 2) {
        //修改
        return true;
      } else if (props.applyDetailData.state == 99) {
        //人事审批
        return true;
      } else if (props.applyDetailData.state == 31) {
        return false;
      } else if (props.applyDetailData.state == 32) {
        return true;
      } else if (props.applyDetailData.state == 33) {
        return true;
      }
    });

    const getData = async key => {
      const res = await selectLeaveApplyById(key);

      try {
        Object.assign(formState, res.data);
        formState.fileUrl = JSON.parse(formState.fileUrl);
        let arr = formState.startTime.split(',');
        let arr1 = [];
        arr.forEach(item => {
          arr1.push(item.split('~'));
        });
        formState.time = arr1;

        if (props.applyDetailData.state == 31) {
          formState.leaveRevoke.revokeType = 1; // formState.timeRe = arr1

          Object.assign(formState.timeRe, arr1);
        }

        formState.reviseCon = formState.updateContractList.length ? JSON.parse(formState.updateContractList[formState.updateContractList.length - 1].fileUrl) : [];
        formState.revisePayroll = formState.revisePayroll == null ? [] : formState.revisePayroll; // if(props.applyDetailData.state == 99){
        //   // formState.withHold = 100
        //   // formState.leaveEffect = 1
        // }

        if (formState.isRevoked) {
          let arr2 = formState.leaveRevoke.startTime.split(',');
          let arr22 = [];
          arr2.forEach(item => {
            arr22.push(item.split('~'));
          });
          Object.assign(formState.timeRe, arr22);
        }

        getRemainDay(res.data.uid);
        console.log(formState);
      } catch (error) {
        console.log('请假详情:', error);
      }
    }; //获取附件的url


    const getAdjuncts = val => {
      // console.log('getAdjuncts',val)
      formState.fileUrl = val;
    };

    const getAdjunctsForRev = val => {
      // console.log('getAdjuncts',val)
      formState.revisePayroll = val;
    };

    const getAdjunctsForRevCon = val => {
      // console.log('getAdjuncts',val)
      formState.reviseCon = val;
    }; //审批


    const handlePassOrReject = async (num, type) => {
      if (!formState.content) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.applyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject
      }; // if(type == 99 && num) {
      //   // param.leaveEffect = formState.leaveEffect
      //   param.withHold = formState.withHold
      // }

      loading.value = true;

      try {
        const res = await updateToLeaveApproval(param);

        if (res.code == 200) {
          _message.success(res.message);

          loading.value = false;
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }
      } catch (error) {
        loading.value = false;
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "审批责任人";

        case 4:
          return "财务审批人";

        case 5:
          return "人事审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        let isOk = false;
        isOk = findDataIsOk(formState.time); // console.log(isOk)

        if (isOk) {
          _message.error('请假时间段不能有重合');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        let arr = [];
        obj.time.forEach(item => {
          arr.push(item.join("~"));
        });
        obj.startTime = arr.join(",");
        loading.value = true;
        let res = await updateLeaveApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //查看历史请假记录


    const historicalVisible = ref(false);

    const toView = async () => {
      historicalVisible.value = true;
    }; // 撤销
    //审批


    const handleUndo = () => {
      formRef.value.validate().then(async () => {
        let isOk = false;
        isOk = findDataIsOk(formState.timeRe); // console.log(isOk)

        if (isOk) {
          _message.error('撤销后请假时间段不能有重合');

          return;
        }

        let arr = [];
        formState.timeRe.forEach(item => {
          arr.push(item.join("~"));
        });
        const param = {
          applyId: formState.applyId,
          revokeType: formState.leaveRevoke.revokeType,
          startTime: arr.join(",")
        };
        loading.value = true;
        const res = await updateRevokeApply(param);

        if (res.code == 200) {
          _message.success(res.message);

          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findDataIsOk = times => {
      for (let i = 0; i < times.length; i++) {
        for (let j = 0; j < times.length; j++) {
          let is = moment(times[i][0]).valueOf();
          let ie = moment(times[i][1]).valueOf();
          let js = moment(times[j][0]).valueOf();
          let je = moment(times[j][1]).valueOf();

          if (i == j) {
            if (is == ie) {
              return true;
            } else {
              continue;
            }
          } else {
            if (is <= je && is >= js || ie >= js && ie <= je || is <= js && ie >= je || is >= js && ie <= je) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    };

    const disabledDate = current => {
      return current && current <= moment().subtract(1, 'days');
    };

    const handleAdd = () => {
      formState.time.push([]);
    };

    const onDelete = key => {
      formState.time.splice(key, 1);
    };

    const handleAddRe = () => {
      if (formState.timeRe.length < formState.time.length) {
        formState.timeRe.push(undefined);
      } else {
        _message.warning('撤销请假时间段不能多于请假时间段');
      }
    };

    const onDeleteRe = key => {
      formState.timeRe.splice(key, 1);
    }; // const handleBlur = () => {
    //   if(formState.withHold === null) {
    //     formState.withHold = 0
    //   }
    // }
    //假期类型


    const remainDayData = ref([]);

    const getRemainDay = async uid => {
      let res = await selectRemainDay({
        uid
      });

      if (res.code === 200) {
        remainDayData.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let obj = {
              value: item.id,
              label: item.holidayName,
              isDayNum: item.isDayNum,
              dayNum: item.dayNum
            };
            remainDayData.value.push(obj);
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const getLeaveDay = (val, label) => {
      let obj = null;

      if (remainDayData.value.length) {
        obj = remainDayData.value.find(item => item.value === val);
      }

      if (obj) {
        return obj[label];
      } else {
        return false;
      }
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 9,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const leaveDays = ref(0);
    watch(() => formState, () => {
      // console.log(111111111,formState.time)
      let arr = formState.time.filter(item => item.length != 0);

      if (arr.length) {
        getLeaveDay1(arr);
      } else {
        formState.leaveDay = 0;
        leaveDays.value = 0;
      }
    }, {
      deep: true
    });

    const getLeaveDay1 = async arr => {
      let arr1 = [];
      arr.forEach(item => {
        arr1.push(item.join("~"));
      });
      let res = await getLeaveDays({
        startTime: arr1.join(",")
      });

      if (res.code == 200) {
        formState.leaveDay = res.data;
        leaveDays.value = res.data;
      } else {
        _message.error(res.message);
      }
    };

    const uid = Cookies.get('uid');
    onMounted(() => {
      getData(props.applyDetailData.key);
      getApprovalItem();
    });
    return {
      formRef,
      formState,
      rules,
      isDisabledForCl,
      closeApplyVisible,
      getAdjuncts,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleOk,
      loading,
      getAdjunctsForRev,
      getAdjunctsForRevCon,
      toView,
      historicalVisible,
      isDisabledForRe,
      handleUndo,
      disabledDate,
      handleAdd,
      onDelete,
      handleAddRe,
      onDeleteRe,
      validateTime,
      // handleBlur,
      remainDayData,
      getLeaveDay,
      projectOptions,
      leaveDays,
      uid
    };
  }

});